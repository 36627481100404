.Passport {
    flex: 1;
    display: flex;
    padding: 0px 0px 0;
    position: relative;
    background: rgb(240, 235, 228);

}

.Passport-background {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    width: 100%;
    display: none;
    height: 100%;
    opacity: 1;
    display: block;
    top: 0;
}

.Passport::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    background: rgb(0, 0, 0) !important;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.Passport-details {
    z-index: 10000;
    flex: 1;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    background: rgba(0, 0, 0, 0.5);

    overflow: hidden;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap');

.Passport-details-title {
    text-align: left;
    padding: 30px 30px 30px;
    overflow: hidden;
    color: #fff;
}

/*     font-family: 'Libre Barcode 128 Text', cursive;
 */


.Passport-details-title em {
    font-size: 30px;
    white-space: nowrap;
    font-style: normal;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    display: block;
    font-family: 'Libre Barcode 128 Text', cursive;
    font-weight: bold;
}

.Passport-details-title em em {
    display: inline;
    font-weight: 100;
    color: rgb(255, 255, 255);
}

.Passport-details-title b {
    font-size: 20px;
    font-style: normal;
    border-radius: 0 5px 5px 0;
    display: inline-flex;
    background: rgb(73, 118, 255);
    margin-left: -35px;
    padding: 10px 0px 10px 40px;

    font-weight: 400;
    position: relative;
    color: rgba(255, 255, 255, 0.816);
    align-items: center;
    margin-top: 2px;

}


.Passport-details-title b b {

    background: rgba(0, 0, 0, 0.301);
    display: inline;
    font-weight: 300;
    margin: 0;
    border-radius: 5px 0 0 5px;

    color: rgba(255, 255, 255, 0.671);
    padding: 5px 10px;
    margin-left: 10px;
    vertical-align: middle;
    font-size: 12px;
}

.Passport-details-title>b:last-child {
    font-size: 18px;
    background: #333
}

.Passport-details-title span {
    font-size: 20px;
    font-style: normal;
    display: block;
    font-weight: 100;
}


.Passport-content {
    padding: 5px 20px 15px 20px;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.927);
    color: #000;
    margin: 0px 30px 0;
    flex: 1;
    border-radius: 7px 7px 0 0;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.Passport-content .Line span {
    font-size: 13px;
    font-weight: bold;
    color: #000;
    letter-spacing: 0;
}

.Passport-content>b {
    color: rgb(0, 0, 0);
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
}

.Passport .cta {
    background: rgb(73, 118, 255) !important;
    border-radius: 5px;
    color: #fff;
    margin-top: 20px;
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 15px;
}

.Passport .cta i {
    margin-right: 10px;
    font-size: 24px;
}