.Header {
    border-radius: 5px 5px;
    max-width: 1400px;
    width: 100%;
    background: #fff;
    flex-direction: column;
    min-height: 50px;
    align-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-left: 55px;
    padding-right: 50px;
}

.Header-title {
    font-size: 15px;
    font-weight: bold;
    display: inline;
    line-height: 50px;
    margin-right: 20px;
}

.Header-description {
    font-size: 15px;
    display: inline;
    opacity: 0.5;
}

.Header-icon {
    font-size: 30px;
    left: 0px;
    line-height: 50px;
    top: 0;
    text-align: center;
    width: 50px;
    border-radius: 5px;
    color: rgb(73, 118, 255);
    bottom: 0;
    position: absolute;
}


.Header-buttons {
    position: absolute;
    top: 0;
    height: 50px;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 7px;

}

.Header-buttons span {
    background: rgb(73, 118, 255) !important;
    color: #fff;
    line-height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 40px;
    max-height: 40px;
    padding: 0 20px 0 0;
    border-radius: 5px;
}

.Header-buttons .withoutText {
    padding: 0;
    width: 40px;
}

.Header-buttons span i {
    font-size: 20px;
    width: 40px;
    text-align: center;
}

.Form-position-center .Header-user {
    display: none;
}

.Header-user em {
    position: absolute;
    background: rgb(234, 239, 255) !important;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    padding: 5px 8px;
    font-style: normal;
    top: -10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 10px;
    font-size: 8px;
    right: 45px;
    color: rgba(0, 0, 0, 0.6);
}

.Header-user {
    cursor: default !important;
    background: rgb(238, 238, 238) !important;
    color: #fff;
    line-height: 40px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 40px;
    display: inline-block;
    color: #000;
    max-height: 40px;
    padding: 0 55px 0 15px;
    border-radius: 5px;
    position: relative;
}

.Header-user-with-avatar {
    padding-left: 55px;
}

.Header-user-avatar {
    height: 100%;
    width: 40px;
    border-radius: 4px;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
}


.Header-user i {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border-radius: 4px;

    line-height: 40px;
    width: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
}

.Header-user i:hover {
    background: rgba(0, 0, 0, 0.2);
}

.Header .iso {
    background: #000000ad;
    width: 40px;
    display: flex;
    position: absolute;
    right: 5px;
    border-radius: 5px 5px;

    top: 5px;
    cursor: pointer;
    bottom: 5px;
    box-sizing: border-box;
    padding: 5px;
    align-items: center;
}


.Header .iso img {
    width: 100%;
    display: block;
}

.Header .ce {
    background: #000000ad;
    width: 40px;
    display: flex;
    position: absolute;
    right: 51px;
    border-radius: 5px 5px;

    top: 5px;
    cursor: pointer;
    bottom: 5px;
    box-sizing: border-box;
    padding: 5px;
    align-items: center;
}

.Header .ce::after {
    content: "Conforme a";
    position: absolute;
    background: rgb(234, 239, 255) !important;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    padding: 5px 8px;
    font-style: normal;
    bottom: -14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 10px;
    font-size: 7px;
    left: 7px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.6);
}

.Header .ce img {
    width: 80%;
    margin-left: 3px;
    display: block;
}