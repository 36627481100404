.Tab {
    padding: 0;
    background: rgba(0, 0, 0, 0.05);
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.Form-box-tab {
    margin-top: 30px;
}

.Form-box-tab+.Form-box-tab {
    margin-top: 10px;

}

.Tab-title {
    line-height: 40px;
    cursor: pointer;
    font-size: 14px;
    padding-left: 15px;
    color: rgb(73, 118, 255);
    font-weight: 500;

    padding-right: 50px;

}

.Tab-title:hover {
    background: rgb(73, 118, 255);
    color: #fff;
}

.Tab-title:hover i {
    color: #fff;
}

.Tab-active {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.147);
}

.Tab-title-active {
    background: rgb(108, 108, 108);
    color: #fff;
    border-radius: 5px;
}

.Tab-title-active i {
    color: #fff !important;
}

.Tab-title i {
    color: #000;
    float: right;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.044);
    width: 40px;
    text-align: center;
    margin-right: -50px;

    border-radius: 5px 0 0 5px;
}

.Tab-content {
    border-top: 1px solid #ddd;
    overflow: hidden;
    border-bottom: 1px solid #ddd;

    background: rgb(255, 255, 255);
    padding: 10px 30px 40px;
}

.Tab-errors {
    color: rgb(255, 108, 108);
    border-radius: 4px;
    background: rgba(255, 208, 208, 0.1);
    margin-left: auto;
    float: right;
    line-height: 25px;
    margin-top: 7px;
    margin-right: -4px;
    padding: 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.09)
}