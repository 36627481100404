.Widgets,
.Widgets .Widgets .Widgets {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1400px;
    flex: 1;
}

.Widgets .Widgets {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.Widget {
    flex: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
}

.Widget-title {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 14px 20px;
    font-weight: 300;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.Widget-content {
    display: flex;
    flex: 1;
    min-height: 300px;
    flex-direction: column;
}


.Widget-card {
    padding: 20px;
    background: rgb(73, 118, 255) !important;
}