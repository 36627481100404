.Select {
    position: relative;
}

.Select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.Select-header {
    background: #ddd;
    line-height: 40px;
    font-size: 15px;
}

.Select ul {
    line-height: 45px;
    padding: 0;

}

.Select .options {
    position: relative;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 5px;
    border: 2px solid rgb(191, 191, 191);
    box-shadow: 0px 2px 23px #aaa;
}

.Select .options .Loading {
    top: 38px;
}

.Select .options input {
    border-left: none;
    border-top: none;
    border-radius: 0 !important;
    outline: none !important;
    border-right: none;
    border-bottom: 1px solid #aaa !important;
}

.Select .options ul {
    padding-left: 0px;
    line-height: 35px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;

}

.Select .options ul li{ margin-right: 10px; padding-left: 13px; flex: 1;}
.Select .options ul li:nth-child(2){
    background: #eee;
    line-height: 20px;
    color: #000 !important;
    padding: 0 10px;
    font-size: 12px; margin: 4px; border-radius: 5px; border:1px solid #ddd;
}

.Select .options ul:last-child {
    border-bottom: none;
}

.Select .Paginator .Paginator-button {
    padding: 0 10px;
    line-height: 30px;
}

.multiple-item {
    display: inline-block;
    background: #eee;
    margin: 5px 5px 0px 0px;

    border-radius: 50px;
    margin-right: 5px;
    padding: 5px 14px;
    text-indent: 0;
    line-height: 15px;
}

.Select .selected,
.Select .unselected {
    line-height: 40px;
    width: 100%;
    padding: 0;
    cursor: pointer;
    overflow: hidden;

    margin: 0;
    background: #fff;
    font-size: 15px;
    text-indent: 15px;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px dashed #ddd
}

.Select ul:hover {
    cursor: pointer;
    background: rgb(73, 118, 255) !important;
    color: #fff;
}

.Select .selections {
    position: relative;
    padding: 0 0 0px 0;
    padding-right: 150px;
    padding-left: 15px;
    text-align: left;
    line-height: 1;
    text-indent: 0;
}

.Select .selections {
    cursor: pointer;
}

.Select .selections i {
    right: 0px;
    top: 0;
    line-height: 40px;
    width: 40px;
    text-align: center;
    border-left: 1px dashed #ccc;
    font-size: 14px;
    opacity: 0.5;
    position: absolute;
}

.Select .selections:hover i {
    background: #eee;
}

.Select .selections b {
    right: 50px;
    line-height: 40px;
    text-align: center;
    top: 0;
    font-size: 13px;
    opacity: 0.5;
    position: absolute;
}


.Select .single-item {
    line-height: 40px;
    white-space: nowrap;
}

.Select .unselected i {
    right: 10px;
    top: 0;
    line-height: 40px;
    width: 50px;
    text-align: center;
    font-size: 18px;
    opacity: 0.5;
    position: absolute;
}

.Select .unselected b {
    right: 50px;
    line-height: 40px;
    text-align: center;
    top: 0;
    font-size: 13px;
    opacity: 0.5;
    position: absolute;
}