.Icons {
    position: relative;
}

.Icons-header {
    background: #ddd;
    line-height: 40px;
    font-size: 15px;
}

.Icons ul {
    border-top: 1px solid #ddd;
    line-height: 45px;
}

.Icons ul:hover {
    cursor: pointer;
    background: #eee;
}

.Icons .item {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    background: #eee;
}

.Icons .list {
    padding: 16px;
    text-indent: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.Icons .item i {
    position: absolute;
    line-height: 80px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 50px;
    padding: 0;
    text-indent: 0;
}


.Icons-header {
    background: #ddd;
    line-height: 40px;
    font-size: 15px;
}

.Icons .unselected {
    position: relative;
}

.Icons .unselected i {
    right: 10px;
    line-height: 40px;
    width: 50px;
    text-align: center;
    font-size: 18px;
    opacity: 0.5;
    position: absolute;
}