.Line {
    display: flex;
    padding: 2px 14px 2px 0px;
    align-items: center;
    position: relative;
    font-size: 25px;
    color: #fff;

    &:last-child {
        border-bottom: none;
    }

    b {
        background: rgba(0, 0, 0, 0.3);
        font-weight: normal;
        min-width: 75px;
        color: rgba(255, 255, 255, 0.7);
        text-align: right;
        max-width: 75px;
        margin-left: -20px;
        border-left: 1px solid rgb(49, 79, 170) !important;
        border-radius: 0 5px 5px 0;
        font-size: 11px;
        padding: 7px 10px;
    }

    i {
        opacity: 0.9;
        display: none;
        color: rgba(73, 118, 255, 0.63);
    }

    span {
        margin-left: 10px;
        font-size: 16px;
        white-space: nowrap;
        font-weight: normal;
        border-radius: 5px;
    }

    &::after {
        height: 6px;
        width: 6px;
        background: rgb(73, 118, 255);
        content: "";
        display: block;
        transform: rotate(135deg);
        position: absolute;
        top: 50%;
        box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.371);
        border-radius: 0 5px 5px 5px;
        margin-top: -3px;
        left: -23px;
    }

    &:nth-child(even) {}
}