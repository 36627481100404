.Table {
    position: relative;
    flex: 1;
}

.Table ul {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #ddd;
    background: #f5f5f5;

    display: flex;
}

.Table .avatar {
    height: 40px;
    min-width: 40px;
    width: 40px;
    background-size: cover;
    border: none !important;
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    background-position: center;
}

.Table .avatar-image {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    opacity: 0.9;
    background-size: cover;
    border: none !important;
    display: block;
    background-position: center;
}

.Table-searcher {
    border-bottom: 1px solid #ddd;
    position: relative;
}

.Table-searcher i {
    position: absolute;
    line-height: 50px;
    left: 0;
    top: 0;
    width: 50px;
    text-align: center;
    display: block;
    pointer-events: none;
}

.Table-searcher input {
    line-height: 50px;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    text-indent: 50px;
}

.Table-error-general {
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
    background: rgb(255, 236, 236);
    border-bottom: 1px solid #ddd;
}

.Table-error-general i {
    margin-right: 15px;
}

.Table-searcher button {
    position: absolute;
    right: 10px;
    background: #eee;
    cursor: pointer;
    border-radius: 5px;
    padding: 0 10px;
    text-transform: uppercase;
    line-height: 30px;
    top: 11px;
}

.Table-searcher button:hover {
    background: #aaa;
    color: #fff
}

.Table .avatar::after {
    position: absolute;
    content: "";
    left: 0;
    filter: none;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.1;
    display: block;
}

.Table li {
    display: flex;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 0 16px;
    border-right: 1px solid #ddd;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;

}

.Table .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.Table li:last-child {
    border-right: none;
}

.Table ul:nth-child(2n) {
    background: rgb(255, 255, 255);
}


.Table li i {
    width: 50px;
    text-align: center;
    font-size: 20px;
}

.Table li span {
    width: 100px;
    margin-left: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.229);
    background: rgba(0, 0, 0, 0.05);
    padding: 5px 20px;
}

.Paginator {
    display: flex;
    padding: 10px;
    gap: 10px;

    align-items: flex-start;
}

.Paginator .Paginator-button {
    padding: 0 20px;
    cursor: pointer;
    line-height: 40px;
    background: #eee;
    text-indent: 0;
    border-radius: 5px;
}

.Paginator .Paginator-button-active {
    background: #eee;
}

.Table-header-total {
    display: inline;
}

.Table-header {
    padding: 15px 15px;
    text-transform: uppercase;
    text-align: right;
    color: #000;
    border-bottom: 1px solid #ddd;
}

.Table .Loading {
    position: relative;
}

.Table-header strong {
    text-transform: none;
    float: left;
    font-weight: normal;
    opacity: 0.4;
}

.Table-header b {
    font-weight: bold;
    margin-left: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.051);
}

.Table-header-title {
    padding: 10px 0 10px 20px;
    text-align: right;
    display: inline;
    font-size: 12px;
}

.Table .link-icon {
    margin-left: auto;
}

.Table-head {
    background: #fff !important;
}

.Table-head b {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 10px;
    color: royalblue;
}

.Table-head .ordering {
    cursor: pointer;
}

.Table-head .ordering:hover {
    background: #ddd !important;
}

.Table-head .la-angle-down,
.Table-head .la-angle-up {
    font-size: 12px;
    float: right;
    margin-left: auto;
}

.Paginator .Paginator-button-active {
    animation: bounceInPagination 600ms ease-in-out;
}



@keyframes bounceInPagination {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {

        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}


.Table-touch {
    cursor: pointer;
    position: relative;
    min-width: 50px
}

.Table-touch:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
}


.Table-rating {
    font-size: 0;
    margin: 0;
    line-height: 40px;
    height: 40px;
}

.Table-rating i {
    font-size: 20px !important;
    padding: 0;
    color: rgb(73, 118, 255) !important;
    display: inline-block;
    margin: 0;
    line-height: 40px;
    width: auto !important;
}

.Table-rating i.Table-rating-desactive {
    color: black !important;
    opacity: 0.2;
}

.Identifier {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: rgb(246, 248, 255) !important;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.637);
    font-size: 13px;
    position: relative;
    width: 150px;
    overflow: hidden;
    height: 25px;
    margin-left: -8px;
    box-sizing: border-box;
    font-weight: BOLD;
    letter-spacing: 1px;
    padding: 0 0 0 0;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: 400;
}

.Identifier b {
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.Identifier i {
    border-right: 1px solid rgba(0, 0, 0, 0.103);
    width: 25px !important;
    line-height: 25px;
    color: rgb(73, 118, 255) !important;
    ;
    height: 25px;
    padding: 0;
    font-size: 15px !important;
    margin: 0;
    margin-right: 7px;

    text-align: center;
    background: rgba(0, 0, 0, 0.048);
    display: inline-block;
}

.Status {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: rgb(255, 255, 255);
    line-height: 25px;
    color: #000;
    font-size: 11px;
    position: relative;
    width: 130px;
    text-transform: uppercase;
    overflow: hidden;
    margin-left: -8px;
    box-sizing: border-box;
    font-weight: BOLD;
    letter-spacing: 1px;
    padding: 0 0 0 33px;
    border-radius: 5px;
}

.Status::before {
    width: 25px;
    background: #e8e8e8;
    left: 0;
    top: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border-right: 1PX SOLID rgba(0, 0, 0, 0.07);
    display: block;

}

.Status::after {
    height: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.556);
    width: 8px;
    background: red;
    content: "";
    display: block;
    position: absolute;
    left: 9px;
    margin-top: -4px;
    border-radius: 10px;
    top: 50%;
    ;
}

.Status-ACTIVE::after {
    background: #59D9B5;
}

.Status-ACTIVE {
    background: #B4E9DA !important;
}

.Status-REJECTED {
    background: rgb(255, 251, 246) !important;
}


.Status-REJECTED::after {
    background: rgb(255, 145, 0);
}

.Status-FINALIZED {
    background: #ECE2FD !important;

}

.Status-FINALIZED::after {
    background: #DDC5FF !important;
}


.Status-RELEASE {
    background: #A3D6C6 !important;

}

.Status-RELEASE::after {
    background: #38B28C !important;
}

.Status-PRINTING::AFTER {
    background: #D1C0BA !important;
}

.Status-PRINTING {
    background: #E6DFDC !important;
}

.Status-FAILED {
    background: #F3C5C7 !important;
}

.Status-FAILED::after {
    background: #F28891 !important;
}

.Status-INACTIVE {
    background: #E8A1A7 !important;
}

.Status-INACTIVE::after {
    background: #E03D51 !important;
}





.Status-PENDING {
    background: #BBF2F2 !important;
}

.Status-PENDING::after {
    background: #e3f8f8 !important;
}


.Status-STERILIZATION {
    background: #B4E9DA !important;
}

.Status-STERILIZATION::after {
    background: #59D9B5 !important;
}


.Status-DENIED {
    background: #E8A1A7 !important;
}

.Status-DENIED::after {
    background: #E03D51 !important;
}


.Status-EXAMINING {
    background: #94BFDB !important;
}

.Status-EXAMINING::after {
    background: #1F82BF !important;
}


.Status-ACTIVE::after {}

@keyframes pulsate {
    0% {
        filter: brightness(1);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    50% {
        filter: brightness(1.3);
    }

    100% {
        filter: brightness(1);

        box-shadow: 0 0 0px 42px rgba(0, 0, 0, 0);
    }
}

.la-folder {
    transform: rotate(-90deg);
}


.Filtering {
    background: rgb(73, 118, 255);
    color: #fff;
    display: block;
    text-align: left;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: -10px;
    width: 100%;
    padding: 14px;
    font-size: 14px;
    max-width: 1400px;
    box-sizing: border-box;
}