.Chat {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    overflow: hidden;
    background: #f0f0f0;
}

.Chater {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 350px;
    z-index: 10000;
    display: flex;
}

.Chat ul {
    flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    overflow-y: scroll;
    padding: 20px 10px;
    max-height: 460px;
}




.Chat *::-webkit-scrollbar {
    width: 8px;
}

.Chat *::-webkit-scrollbar-thumb {
    background: #aaa;
    width: 5px;
    border-radius: 10px;

}

.Chat *::-webkit-scrollbar-track {
    background: transparent;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.Chat *::-webkit-scrollbar-button {
    background: #aaa;
    height: 0;
    width: 0;
    border-radius: 50%;
}

.Chat ul li {
    display: block;
    position: relative;
    padding: 0px;
    width: 100%;
}

.Chat ul li .avatar {
    width: 40px;
    height: 40px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);

    border-radius: 50%;
    background: #fff;
    display: flex;
    position: absolute;
    left: 0;
    top: 8px;
    align-items: center;
    background-position: center;
    background-size: cover;
    justify-content: center;
    margin-right: 10px;
}

.Chat ul li p {

    display: inline-block;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
}

.Chat ul li p i {
    font-size: 25px;
    margin-right: 10px;
}

.Chat ul li.me .message {
    background: rgb(73, 118, 255);
    color: #fff;
    border-radius: 10px;

}

.Chat .other+.me {
    margin-top: 10px;
}


.Chat ul li strong {
    display: block;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    margin-top: 15px;
    font-weight: bold;
    font-size: 14px;
    padding: 0px 0 0 50px;
}

.Chat ul li em {
    font-style: normal;
    display: inline-block;
    margin-top: 0px;
    line-height: 17px;
    font-weight: 300;
    white-space: nowrap;
    opacity: 0.8;
    padding: 0px 0px 0px 10px;

    font-size: 10px;
    text-align: right;
}

.Chat .message {
    display: inline-flex;
    background: rgb(255, 255, 255);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    padding: 7px 10px 7px 10px;
    margin-left: 20px;
    border-radius: 0px 10px 10px 10px;

}

.Chat .me .message {
    float: right;
    margin-left: 100px;
    justify-content: end;
}



.Chat ul li strong b {
    margin-left: 6px;
    display: inline-block;
    background: rgb(73, 118, 255) !important;
    padding: 3px 7px;
    transform: translateY(-2px);
    font-weight: 300;
    font-size: 10px;
    border-radius: 10px;

    color: #fff;
}

.Chat ul li span {
    display: block;
    font-size: 11px;
    margin-left: 50px;
    opacity: 0.7;
    margin-top: 0;
    margin-bottom: 10px;

}



.Chat-form {
    max-height: 60px;
    flex: 1;
    font-size: 0;
    display: flex;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.178);
    flex-direction: column;
    position: relative;
}

.Chat-form input {
    height: 60px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-y: scroll;
    border-radius: 0px;
    resize: none;
    overflow-x: hidden;
    outline: none;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    font-size: 16px;
    padding-left: 20px;
    margin: 0;

}

.Chat-form button {
    height: 40px;
    width: 100%;

    margin: 0;
    z-index: 10;
    padding: 0 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: rgb(162, 162, 162);
    border: none;
    display: none;
}