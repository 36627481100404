.Notifications {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    overflow: hidden;
    background: #f0f0f0;
}

.Notifications ul {
    flex: 1;
    overflow-y: scroll;
    max-height: 540px;
    padding: 10px;

}

.Notifications ul li {
    flex: 1;
    padding: 5px 10px 5px 30px;
    position: relative;
    border-radius: 5px;
    margin: 10px;
    display: block;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
    background: rgb(255, 255, 255);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 7px 10px 7px 10px;
    margin-left: 20px;
    border-radius: 10px
}

.Notifications ul li p {

    display: block;

    white-space: nowrap;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    padding: 5px 15px 0 30px;
    line-height: 20px;
}

.Notifications ul li i {
    font-size: 25px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
    left: -10px;
    top: 50%;
    margin-top: -20px;
    color: #fff;
    background: rgb(73, 118, 255) !important;
    position: absolute;
    margin-right: 10px;
}

.Notifications ul li strong {
    display: block;
    border-radius: 10px;
    color: #000;
    margin-top: 5px;
    font-size: 16px;
    padding: 0px;
}


.Notifications ul li em {
    display: block;
    font-size: 12px;
    opacity: 0.5;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    text-align: left;
    margin-top: 3px;
    font-style: normal;
    padding: 0px 0 5px 30px;
}

.Notifications-form {
    max-height: 50px;
    flex: 1;

    display: flex;
    flex-direction: column;
    position: relative;
}

.Notifications-form input {
    height: 50px;
    border: none;
    border-radius: 0;
    font-size: 16px;
    padding-left: 20px;
}

.Notifications-form button {
    height: 40px;

    position: absolute;
    z-index: 10;
    right: 5px;
    padding: 0 20px;
    top: 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: blue;
    border: none;
    border-radius: 0;
}