.Region {}

.Gallery {
    display: grid;
    padding: 10px;
}

.Gallery {
    display: grid;
    border-radius: 5px;

    grid-template-columns: 3fr 3fr 3fr;
}

.Gallery-header {
    color: #000;
    position: relative;
    background-repeat: no-repeat;
    z-index: 1;
    background-size: 100% auto;
    background-position: bottom left;
    padding: 10%;

}

.Gallery-header em {
    display: block;
    font-style: normal;
    font-size: 20px;
    margin-top: 20px
}

.Gallery-header em i {
    display: block;
    font-style: normal;
    margin-top: 5px;
    opacity: 0.5;
    font-size: 13px;
}

.Gallery-header img {
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    opacity: 0.2;
    bottom: -40px;
    left: 35px;
    fill: #fff !important;
    width: 400px;
}

.Gallery-header .Gallery-header-logo {
    background: rgba(0, 0, 0, 0.083);
}

.Gallery-header-logo {
    height: 80px;
    width: 80px;
    position: absolute;
    left: 10%;
    bottom: 10%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.Gallery .Region .Gallery-header-logo {
    top: 10%;
    opacity: 0;
    bottom: auto;
    left: auto;
    z-index: 1000;
    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    right: -10%;
    transform: scale(1.5);

}

.Gallery .Region:hover .Gallery-header-logo {
    opacity: 1;
    transform: scale(0.8);
    right: 10%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

}

.Gallery-header-logo svg {
    position: absolute;
    left: 24px;
    top: 17px;
}

.Gallery-header p {
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
}

.Gallery span {
    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    color: #fff;
    background: rgb(73, 118, 255) !important;
    text-decoration: none;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    position: absolute;
    border-radius: 100px;
    right: 33px;

    bottom: 33px;
    transition-delay: 500ms;
}


.Gallery .Region {
    aspect-ratio: 1;
    position: relative;

}

.Gallery .Region>div {
    cursor: pointer;
    background: rgb(240, 235, 228);
    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    position: relative;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 5px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

    margin: 2%;
}

.Gallery .Region:hover>div {

    transform: scale(1.05);
    border-radius: 10px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);

    z-index: 10000;

    margin: 5%;

}

.Gallery i {
    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
}

.Gallery .Region:hover>div span {
    letter-spacing: 3px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);

    margin-right: -50px;
}


.Gallery .Region:hover>div span i {
    margin-left: 20px;
}

.Gallery .Region .Region-background {
    border-radius: 5px;

}

.Gallery .Region:hover .Region-background {
    filter: invert(100%);
    border-radius: 10px;

}

.Gallery .Region-background {
    position: absolute;
    filter: invert(0%);

    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    left: -0px;
    bottom: -0px;

    right: -0px;
    overflow: hidden;

    top: -0px;
    background-size: cover;
    overflow: hidden;
}


.Gallery .Region-background-image {
    position: absolute;
    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    left: -5px;
    bottom: -5px;

    right: -5px;
    top: -5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.Gallery .Region b {
    font-size: 20px;
    background: rgba(255, 255, 255, 0.8);
    color: #000;
    bottom: -20px;
    left: -20px;
    right: -20px;
    line-height: 100px;
    padding-bottom: 20px;
    padding-left: 50px;
    position: absolute;
    font-weight: 400;
    -webkit-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);

    -moz-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    -o-transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
    transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
}

.Gallery .Region:hover b {
    font-weight: bold;
}

.Gallery .video {
    aspect-ratio: 1;
    margin: 2%;
    border-radius: 5px;

    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.314);

    overflow: hidden;
    position: relative;
    background-color: rgb(93, 98, 112);
    object-fit: cover;

}

.Gallery .video :hover {}

.Gallery .video .play {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 100;
    height: 40px;
    width: 40px;
    background: #fff;
    cursor: pointer;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.314);
}

.Gallery .video .mute {
    position: absolute;
    cursor: pointer;
    left: 95px;
    bottom: 40px;
    z-index: 100;
    height: 40px;
    width: 40px;
    background: #fff;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.314);
}

.Gallery .video video {
    aspect-ratio: 1;
    position: relative;
    background-color: rgb(93, 98, 112);
    object-fit: cover;

}

.Gallery .video .titles {
    font-size: 20px;
    padding: 10%;
    position: absolute;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    color: #fff;
    font-weight: 300;
    font-size: 33px;
    line-height: 1.4;
    top: 0;
    right: 0;
    bottom: 0;
}