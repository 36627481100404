@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

textarea {
  font-family: 'Lato', sans-serif;
}

html,
body {
  min-width: 1000px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.SlideUp {


  animation-duration: 1s;
  animation-delay: 100ms;
  animation-fill-mode: both;
  animation-name: slideInDown;
  animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translate3d(0%, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}