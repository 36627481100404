body,
html,
:root {
  background-color: #dfe2e7;
}

body {}

* {
  scroll-behavior: smooth;
}

.root {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.chatra--style-tab {
  z-index: 100000000 !important;
}

.App {
  background-color: rgb(230, 230, 230);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  min-width: 1700px;


}

.toolper {
  max-width: 300px;
  line-height: 1.3;
  padding: 7px 11px !important;
  z-index: 100000000000000 !important;
  font-size: 13px !important;
  background: rgb(73, 118, 255) !important;
  border-radius: 5px !important;


}

.toolper:after {
  border-bottom-color: rgb(73, 118, 255) !important;
}

.toolper:before {
  border-bottom-color: rgb(73, 118, 255) !important;
}


.Content,
.Content-big {
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

.Content-big {
  padding: 0;
}

iframe {
  height: 100%;
  width: 100%;
}


.App iframe {

  display: block;
  height: 100%;
  width: 100%;
  margin: 0px auto 0;
  display: block;
  background: #fff;
  border: none;
}

.Bar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  padding: 0px;
  width: 500px;
  box-sizing: border-box;
  background-image: linear-gradient(0deg, rgb(38, 38, 38) 0%, rgb(113, 113, 113) 100%);
  color: #000;

}

.Bar-Scroll {
  flex: 1;
  overflow-y: scroll;
}

.Video {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.Video video {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;

  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}


.Back {
  background: #000;
  color: #fff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin: 20px;
  text-align: center;
  border-radius: 100px;
}

.List {
  border-radius: 10px;
  color: #fff;

}

.List-item {
  height: 60px;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.514);
}

.List-item:last-child {
  border-bottom: none;
}

.List-item:hover i {
  background: black;
}

.List-item b {
  padding-left: 20px;
}

.List-item i {
  margin-left: auto;
  height: 40px;
  margin-right: 10px;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  width: 40px;
  background: blue;
}

.List-title {
  padding: 20px;
  position: sticky;
  top: 0;
  font-size: 20px;
  text-shadow: 0px 5px #000;
  font-weight: 200;
  letter-spacing: 2px;
  background: rgba(0, 0, 0, 0.356);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
}




*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #aaa;
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
  padding: 5px;
}

*::-webkit-scrollbar-button {
  background: #aaa;
  height: 0;
  width: 0;
  border-radius: 50%;
}

.btn-info {
  background-color: #00000093;
  color: rgba(255, 255, 255, 0.708);
  border: none;
  padding: 20px 0;
  width: 100%;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 15px;
}