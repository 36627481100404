.Excel {
    border: 1px dashed #ccc;
    border-radius: 5px;
    padding: 5px;
}

.Excel ol {
    display: flex;
    border-radius: 5px;
    margin-bottom: 5px;
    gap: 5px;

    font-size: 12px;
}

.Excel ol li {
    flex: 1;
    padding: 5px 5px;
    opacity: 0.6;
    box-sizing: border-box;
}

.Excel ol li {}

.Excel ol li:first-child {
    border-left: none;
}

.Excel ul li {
    display: flex;
    gap: 5px;
}

.Excel ul li div {
    flex: 1;
    margin-bottom: 5px;
}

.Excel .remove {
    position: absolute;
    right: -31px;
    top: 50%;
    margin-top: -12px;
    height: 20px;
    color: #fff;
    width: 20px;
    background: rgb(226, 90, 90);
    border-radius: 100px;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
}

.Excel ul li {
    position: relative;
}

.Excel ul li div input {
    font-size: 13px;
    outline: none !important;
    text-indent: 10px;
    border-color: #aaa;
    line-height: 30px !important;
    border-style: solid !important;
}

.Excel ul li div:first-child input {}


.Excel .add {
    margin-left: auto;
    float: right;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    color: rgb(73, 118, 255);
}

.Excel::after {
    content: "";
    clear: both;
    display: table;

}