.Menu {
    width: 350px;
    flex: 1;
    max-width: 350px;
    z-index: 1000000;
    position: relative;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    box-shadow: rgb(0 0 0 / 5%) -2px 0px 10px, rgb(0 0 0 / 0%) 0px 0.3px 0.3px, rgb(0 0 0 / 0%) 0px 0.7px 0.7px, rgb(0 0 0 / 1%) 0px 1.3px 1.4px, rgb(0 0 0 / 2%) 0px 2.2px 2.5px, rgb(0 0 0 / 2%) 0px 4.2px 4.6px, rgb(0 0 0 / 4%) 0px 10px 11px
}

.Menu::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    opacity: 0;
}


.Menu .legal {
    font-size: 10px;
    line-height: 1.4;
    color: #aaa;
    position: relative;
    max-width: 300px;
    padding: 20px 0 50px 30px;
}

.Menu::-webkit-scrollbar-track-piece {
    background-color: #fff;
}

.Menu::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #fff;
}

.Menu:hover::-webkit-scrollbar-track-piece {
    background-color: #ddd;
}

.Menu:hover::-webkit-scrollbar {
    width: 10px;
}

.Menu:hover::-webkit-scrollbar-thumb {
    background-color: #aaa;
}

.Menu a {
    padding: 0 20px;
    line-height: 50px;
    display: block;
    background: #fff;
    color: #000;
    text-decoration: none;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

.Menu .audio {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    left: 300px;
    color: #666;
    border-radius: 100px;
    top: 33px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
}

.Menu .audio:hover {
    background: rgba(0, 0, 0, 0.1);
}

.Menu>p {
    padding: 20px 0 10px 35px;
    line-height: 20px;
    margin-bottom: 0px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    font-size: 12px;
    background: #fff;
    color: #000;
    text-decoration: none;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;

}

.Menu a b {
    margin-left: auto;
    background: rgba(0, 0, 0, 0.07);
    line-height: 26px;
    padding: 0px 10px;
    font-weight: 300;
    color: #000;
    position: absolute;
    left: 300px;
    top: 50%;
    margin-top: -13px;
    border-radius: 5px;
    font-size: 13px;
}

.Menu a:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.099);
}

.Menu a i {
    font-size: 25px;
    width: 50px;
    display: inline-block;
    text-align: center;
    opacity: 0.3;
}

.Menu a i.image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
}

.Menu a.active {
    background: rgb(73, 118, 255) !important;
    color: #fff;
}

.Menu a.active i {
    color: #fff;
    opacity: 1;
}

.Menu a.active b {
    background: rgba(236, 233, 233, 0.325);
    color: #fff;
}

.Menu .section a.active {
    background: rgba(0, 0, 0, 0.3) !important;
}

.Menu a.active::before {
    height: 20px;
    width: 5px;
    background: rgb(0, 0, 0);
    border-radius: 0 5px 5px 0;
    content: "";
    left: 0;
    top: 50%;
    opacity: 0.5;
    position: absolute;
    margin-top: -10px;
    display: block;
}

.Menu .section {
    background: rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
}

.Menu .section a {
    background: none;
    padding-left: 30px;
    line-height: 40px;

    border-bottom: none;
    animation: bounceInMenu 600ms ease-in-out;
}



@keyframes bounceInMenu {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {

        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.Menu .logo {
    position: relative;

    padding: 30px 0 11px 30px;
    border-bottom: 1px solid #eee;
}

.Menu .logo svg {
    width: 100px;
    margin-left: 2px;
}

.Menu .logo b {
    display: inline-block;
    color: rgb(73, 118, 255);
    font-size: 30px;
    line-height: 1;
    position: relative;

}

.Menu .logo span {
    display: block;
    color: rgba(0, 0, 0, 0.3);
    font-size: 17px;
    line-height: 1;
    margin-top: -1px;
    font-weight: 400;
}

.Menu .logo em {
    color: rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    font-size: 11px;
    padding: 5px 10px;
    position: absolute;
    left: 100%;
    white-space: nowrap;
    text-transform: uppercase;
    opacity: 0.5;
    z-index: 100;
    margin-left: -5px;
    top: -10px;
    font-style: normal;
}

.Menu .logo img {
    display: block;
    width: 200px;
}

.project-selected {
    background: #55575b;
    position: sticky;
    top: 0;
    z-index: 10000000;
    color: #fff;
}

.project-selected span {
    max-width: 200px;
    display: block;
    line-height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.project-selected span i {
    font-size: 25px;
    width: 50px;
    display: inline-block;
    text-align: center;
    opacity: 0.3;
}

.project-selected .project-title>i {
    position: absolute;
    left: 300px;
    display: block;
    border-radius: 100px;
    padding: 0;
    top: 50%;
    margin-top: -20px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    width: 40px;
    font-size: 17px;
    cursor: pointer;
}




.Menu {


    animation-duration: 1s;

    animation-fill-mode: both;
    animation-name: MenuShow;
    animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

@keyframes MenuShow {
    from {
        transform: translateX(-300px) translate3d(-10%, 0%, 0) skewX(-10deg);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


.register-success {
    padding: 30px;
    color: rgb(70, 128, 70);
    line-height: 1.5;
    font-size: 14px;
    margin-left: 30px;
    border-left: 2px solid rgb(70, 187, 70);
}

.Menu .version {
    position: absolute;
    right: 20px;
    font-size: 15px;
    bottom: 20px;
}